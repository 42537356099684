import React, { useEffect, useState, useRef, useMemo } from "react";
import withRouter from "../../../components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
    Col,
    Container,
    Row,

} from "reactstrap";

import Select from "react-select";

import { getOverAllInventorySales } from "../../../middleware/backent_helper";
import { Description, InventoryDates, Remarks } from "./inventoryCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/datatables.scss";
//New
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
//redux
import { useDispatch } from "react-redux";

const InventorySalesReport = props => {
    //meta title
    document.title = "Inventory Sales | Accounts ";

    const dispatch = useDispatch();
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [transactionDate, setStartDate] = useState(new Date());
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [inventorySaleList, setInventorySaleList] = useState([]);
    const [totalInventoryCash, setTotalInventoryCost] = useState([]);
    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    )

    const today = new Date();
    const [selectedDates, setSelectedDates] = useState([
        {
            startDate: today,
            endDate: today,
            key: "selection",
        },
    ]);
    const formattedDateRange = `${format(selectedDates[0].startDate, "dd-MM-yyyy")} / ${format(selectedDates[0].endDate, "dd-MM-yyyy")}`;


    const handleDateRangeChange = (ranges) => {
        setSelectedDates([ranges.selection]);
        setDatePickerOpen(false);
    };

    const handleInputClick = () => {
        setDatePickerOpen(true);
    };

    useEffect(() => {
        if (fromDate && toDate) {
            handleSearch(fromDate, toDate);
        }
    }, []);

    const handleClickOutside = (event) => {
        if (node.current && !node.current.contains(event.target)) {
            setDatePickerOpen(false);
        }
    };

    useEffect(() => {
        if (datePickerOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [datePickerOpen]);

    const columns = useMemo(
        () => [
            {
                Header: "S.No",
                Cell: ({ row, rows }) => {
                    return (
                        <div className="table-body">
                            {rows.length - row.index}
                        </div>
                    );
                },
            },

            {
                Header: "Description",
                accessor: "Description",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <div className="table-cell text-center">
                            <Description {...cellProps} />

                        </div>
                    );

                },
            },

            {
                Header: "Inventory Date",
                accessor: "transactionDate",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <div className="table-cell text-center">
                            <InventoryDates {...cellProps} />
                        </div>
                    );

                },
            },

            {
                Header: "Inventory Cash",
                accessor: "inventoryCash",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <div className="table-cell text-center">
                            {cellProps.value}
                        </div>
                    );
                },
            },

            {
                Header: "Remarks",
                accessor: "remarks",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <div className="table-cell text-center">
                            <Remarks {...cellProps} />
                        </div>
                    );
                },
            },
        ],
        []);

    useEffect(() => {
        finalList();
    }, [])

    const finalList = () => {
        handleSearch(fromDate, toDate)
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSearch = (startDate, endDate) => {
        if (!startDate || !endDate) return;
        const hotelId = hid.replace(/"/g, '');
        const formattedFromDate = formatDate(startDate);
        const formattedToDate = formatDate(endDate);
        getOverAllInventorySales(hotelId, formattedFromDate, formattedToDate, dbName)
            .then((res) => {
                setInventorySaleList(res.data.data);
                setTotalInventoryCost(res.data.totalInventoryCash);
            })
            .catch((err) => {
                console.error("Error fetching Inventory Sales data:", err);
            });
    };



    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Reports" breadcrumbItem="Manage Inventory Sales Report" />
                    <div className="mb-3">
                        <Row>
                            <Col lg="6" xs="12" md="6">
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <span className="input-group-prepand">From - To</span>
                                    </div>
                                    <input
                                        type="text"
                                        value={formattedDateRange}
                                        readOnly
                                        onClick={handleInputClick}
                                        placeholder="Select Date Range"
                                        className="form-control"
                                    />
                                    <label className="input-group-text" htmlFor="inputGroupFile01">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </label>
                                </div>

                            </Col>
                            <Col lg="6" xs="6" md="4" className="mt-3 mt-md-0  text-md-left">
                                <button
                                    className="btn btn-success"
                                    onClick={() => handleSearch(selectedDates[0].startDate, selectedDates[0].endDate)}
                                >
                                    <span className="fas fa-search" /> Search
                                </button>
                            </Col>
                        </Row>
                        {datePickerOpen && (
                            <div ref={node} className="date-range-picker-dropdown mt-1">
                                <DateRangePicker
                                    onChange={handleDateRangeChange}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={selectedDates}
                                    direction="horizontal"
                                    className="date-range-picker-dropdown"

                                />
                            </div>
                        )}
                    </div>
                    <Row className="mt-3">
                        <Col lg="12">
                            <TableContainer
                                columns={columns}
                                data={inventorySaleList}
                                isGlobalFilter={true}
                                InventorySalesReport={true}
                                totalInventoryCash={totalInventoryCash}
                                customPageSize={10}
                                className="custom-header-css"
                            />


                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default withRouter(InventorySalesReport);